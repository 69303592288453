
import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import GRAPH_TYPE from '@/modules/markets/constants/graph-types.constant';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import MarketsGraphHotels from '@/modules/markets/components/graph/markets-graph-hotels.vue';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import MarketsGraphHeader from './markets-graph-header.vue';
import MarketsGraphRange from './markets-graph-range.vue';

@Component({
    components: {
        MarketsGraphRange, MarketsGraphHotels, MarketsGraphHeader,
    },
})
export default class GraphSet extends Vue {
    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(MarketsServiceS)
    private marketsService!: MarketsService;

    get providers() {
        if (!this.compsetsService.currentCompset) {
            return [];
        }
        return this.marketsService.providers;
    }

    isRange(provider: string) : boolean {
        return this.marketsService.getGraphType(provider) === GRAPH_TYPE.RANGE;
    }
}

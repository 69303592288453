
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import CompsetDocumentFilter from '@/modules/document-filters/components/compset-type-document-filter.vue';
import ProviderFilter from '@/modules/markets/components/actions/provider-type-filter.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DownloadExcelBtn from '@/modules/common/modules/markets/components/markets-excel-btn.vue';
import LosMarketFilter from '@/modules/markets/components/filters/los-market-filter.vue';
import PosMarketFilter from '@/modules/markets/components/filters/pos-market-filter.vue';
import PosMarketCalendarFilter from '@/modules/markets/components/filters/pos-market-calendar-filter.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import getParentNonModalRoute from '@/modules/common/filters/no-modal-route.filter';

@Component({
    components: {
        CustomSelect,
        CompsetDocumentFilter,
        ProviderFilter,
        DownloadExcelBtn,
        LosMarketFilter,
        PosMarketFilter,
        PosMarketCalendarFilter,
    },
})
export default class MarketsActions extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    get currentPath() {
        return this.$route.path;
    }

    get clearRouteName() {
        return getParentNonModalRoute(this.$router)
            .replace(/\.(table|graphs)/, '');
    }

    get calendarRoute() {
        return {
            name: this.clearRouteName,
            params: this.$route.params,
        };
    }

    get isCalendarMode() {
        return this.$route.name! === this.clearRouteName;
    }

    get tableRoute() {
        return {
            name: `${this.clearRouteName}.table`,
            params: this.$route.params,
        };
    }

    get isTableMode() {
        return this.$route.name!.includes('.table');
    }

    get graphRoute() {
        return {
            name: `${this.clearRouteName}.graphs`,
            params: this.$route.params,
        };
    }

    get isGraphMode() {
        return this.$route.name!.includes('.graphs');
    }
}

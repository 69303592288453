import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import BaseOtelMixin from '../common/base-otel.mixin';
import { LOGTYPE } from '../../constants';

/**
 * Mixin for markets pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class MarketsPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(MarketsFiltersServiceS) marketsFiltersService!: MarketsFiltersService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsServiceS) marketsService!: MarketsService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(MarketsPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', MarketsPageOtelLogs.spanPrefix);
        this.marketsService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', MarketsPageOtelLogs.spanPrefix);
                this.endSpan(MarketsPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.payload });
            });
    }

    beforeDestroy() {
        this.endSpan(MarketsPageOtelLogs.spanPrefix);
    }

    private get payload() {
        const filterToBuild = ['compsetId', 'los', 'pos', 'year', 'month'];
        const settingsUnion = {
            ...this.documentFiltersService.settings,
            ...this.marketsFiltersService.storeState.settings,
        } as Record<string, any>;

        const filterBy = filterToBuild.reduce((acc, key) => {
            acc[key] = settingsUnion[key];
            return acc;
        }, {} as any);

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(filterBy),
        };
    }
}

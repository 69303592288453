
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CustomSelect },
})
export default class LosMarketFilter extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    get los() {
        return this.documentFiltersService.settings.los || 1;
    }
    set los(value: number) {
        this.documentFiltersService.saveLos(value);
    }
    get losItems(): Item[] {
        return DEFAULT_LOS.map(los => ({
            value: los,
            name: this.$tc('filters.los.num', 0, [los]),
        }));
    }
}


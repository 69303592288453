

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import MarketsHintTooltip from '@/modules/markets/components/markets-hint-tooltip.vue';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';

@Component({
    components: {
        DateDocumentFilter,
        MarketsHintTooltip,
    },
})
export default class MarketsHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserViewServiceS) private userViewService!: UserViewService;

    infoTooltip: boolean = false;

    get isChainOrCluser() {
        return this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;
        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

export interface ICustomData {
    id: number;
    color: string;
    name: string;
    persistent?: boolean;
    indicatorClass?: string;
}

@Component({
    components: {
        GraphLegendIcon,
    },
})
export default class GraphHotelsLegend extends Vue {
    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Prop({
        type: Array,
        default: () => [],
    })
    value!: (number | string)[];

    @Prop({
        type: Array,
    })
    private customList!: ICustomData[];

    @Prop({
        type: Array,
    })
    private hotelList!: number[];

    get mainHotelId() {
        return +this.$route.params.hotelId;
    }

    get isCurrentHotelDisabled() {
        return this.value.includes(this.mainHotelId);
    }

    get hotelsGraphColor() {
        return this.hotelsService.getHotelsGraphColor();
    }

    get hotels() {
        const competitors = this.compsetsService.competitors || [];
        let list: (string | number)[] = [];

        if (this.customList) {
            list = this.customList.map(el => el.id);
        } else {
            list = this.ratesService.isAllChannelsMode
                ? this.ratesService.availableProviders
                : this.hotelList || [this.mainHotelId, ...competitors];
        }

        const res = list
            .map((hotelId, index) => {
                const disabled = this.isHotelDisabled(+hotelId || hotelId);
                const name = !this.customList
                    ? this.hotelsService.getHotelName(+hotelId)
                        || this.providersService.getProviderLabel(hotelId as string)
                    : this.customList[index].name;

                const main = hotelId === this.mainHotelId;
                const actualColor = this.customList
                    ? this.customList[index].color
                    : (this.hotelsGraphColor[hotelId] || this.userService.chartColors[index]);

                const color = main
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : actualColor;

                const persistent = this.customList
                    ? this.customList[index].persistent
                    : false;

                const indicatorClass = this.customList
                    ? (this.customList[index].indicatorClass || 'graph-legend')
                    : 'graph-legend';

                return {
                    name,
                    disabled,
                    color,
                    hotelId,
                    main,
                    persistent,
                    indicatorClass,
                };
            });

        if (!this.customList) {
            res.sort((a, b) => (a.main || b.main
                ? 1
                : +a.hotelId - +b.hotelId));
        }

        return res;
    }

    isHotelDisabled(hotelId: number | string) {
        return this.value.includes(+hotelId || hotelId);
    }

    toggleHotel(hotelId: number) {
        let newVal = [...this.value];

        newVal = this.isHotelDisabled(hotelId)
            ? newVal.filter((el: number | string) => el !== hotelId)
            : [...newVal, hotelId];

        this.$emit('input', newVal);
    }

    resetGraph() {
        this.$emit('input', []);
    }
}


import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type Day from '@/modules/common/types/day.type';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { Inject } from 'inversify-props';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import GraphHotelsLegend from '@/modules/common/components/graph-hotels-legend.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import MarketsPreviewTooltip from '../calendar/markets-preview-tooltip.vue';

export interface IDisabled {
    val: boolean;
    id: number;
}

@Component({
    components: {
        CustomGraph,
        LoaderWrapper,
        GraphHotelsLegend,
        MarketsPreviewTooltip,
    },
})
export default class MarketsGraphHotels extends Vue {
    @Inject(MarketsServiceS)
    protected marketsService!: MarketsService;

    @Inject(HotelsServiceS)
    protected hotelsService!: HotelsService;

    @Inject(UserServiceS)
    protected userService!: UserService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    public hiddenGraphs: number[] = [];

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    get mainHotelId() {
        return +this.$route.params.hotelId;
    }

    get chartData(): { labels: (Day | string)[][], datasets: ChartDataSets[] } {
        const datasets: ChartDataSets[] = [];
        const { competitors } = this.compsetsService;
        const { currentHotelId } = this.userService;
        const { days, year, month } = this.documentFiltersService;

        if (!days || !competitors || !currentHotelId) {
            return {
                labels: [],
                datasets: [],
            };
        }

        datasets
            .unshift(this.dataSetByHotel(currentHotelId));
        competitors
            .forEach(hotelId => datasets.push(this.dataSetByHotel(hotelId)));

        const labels = days
            .map(day => [day, this.$t(`dayShort.${new Date(year, month, day).getDay()}`).toString()]);

        return {
            labels,
            datasets,
        };
    }

    get hotelId() {
        const { currentHotelId } = this.userService;

        return +this.$route.params.hotelId || currentHotelId;
    }

    get isLoading() {
        const { isLoading, data } = this.marketsService;
        return (!data[this.provider] && isLoading) || isLoading;
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        callback(value) {
                            return value < 10 ? `0${value}` : value;
                        },
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: this.$tc('markets.popup.position'),
                    },
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    offset: true,
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        reverse: true,
                        callback(value) {
                            return `#${value}`;
                        },
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    showDayMarketsPopup(day: string) {
        return this.$router.push(`graphs/day-markets/${this.parseLabel(day)}/${this.provider}`);
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    dataSetByHotel(hotelId: number):ChartDataSets {
        const { mainHotelId } = this;

        const isGraphDisabled = this.hiddenGraphs.includes(hotelId);
        const borderWidth = hotelId === mainHotelId ? 3 : 2;

        return {
            label: String(hotelId),
            data: this.dataByHotel(hotelId),
            borderColor: this.borderColor(hotelId),
            borderJoinStyle: 'round',
            lineTension: 0,
            borderWidth,
            hidden: isGraphDisabled,
        };
    }

    dataByHotel(hotelId: number): (number | null)[] {
        const data: (number | null)[] = [];

        this.documentFiltersService.days.forEach(day => {
            const checkinDate = this.marketsService.checkinDate(day, this.provider);

            if (checkinDate && checkinDate[hotelId]) {
                data.push(checkinDate[hotelId].position);
            } else {
                data.push(null);
            }
        });

        return data;
    }

    borderColor(hotelId: number) {
        const { currentHotelId } = this.userService;
        return currentHotelId && hotelId === currentHotelId ? CURRENT_HOTEL_GRAPH_COLOR : this.colorByHotel(hotelId);
    }

    colorByHotel(hotelId: number) {
        return this.hotelsService.getHotelsGraphColor()[hotelId];
    }

    resetGraph() {
        this.hiddenGraphs = [];
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    setCurrentDay(rawString: string) {
        this.tooltipDay = this.parseLabel(rawString) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }
}
